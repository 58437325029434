// frontend/src/components/ProfileWizard.js

import React, {useEffect, useState} from 'react';
import api from '../api';

const ProfileWizard = ({ onComplete }) => {
    const [genderId, setGenderId] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [genders, setGenders] = useState([]);

    useEffect(() => {
        fetchGenders();
    }, []);

    const fetchGenders = async () => {
        try {
            const response = await api.get('/genders');
            setGenders(response.data.data.genders);
        } catch (error) {
            console.error('Error fetching genders:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('/profiles', { gender_id: genderId, birthdate });
            onComplete();
        } catch (error) {
            console.error('Error creating profile:', error);
        }
    };

    return (
        <div className="wizard-container">
            <h2>Заполните данные вашего профиля</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Пол:</label>
                    <select value={genderId} onChange={(e) => setGenderId(e.target.value)}>
                        <option value="">Выберите пол</option>
                        {genders.map((gender) => (
                            <option key={gender.gender_id} value={gender.gender_id}>
                                {gender.gender_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Дата рождения:</label>
                    <input
                        type="date"
                        value={birthdate}
                        onChange={(e) => setBirthdate(e.target.value)}
                    />
                </div>
                <button type="submit">Сохранить</button>
            </form>
        </div>
    );
};

export default ProfileWizard;
