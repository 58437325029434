import React, { useState, useEffect } from 'react';
import api from '../api';
import AddResultForm from '../components/AddResultForm';
import Modal from '../components/Modal';
import TestCard from '../components/TestCard';
import TestDetailsModal from '../components/TestDetailsModal';
import './styles/MyTests.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faUpload} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {API_BASE_URL} from "../config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyTests = () => {
    const [showForm, setShowForm] = useState(false);
    const [tests, setTests] = useState([]);
    const [resultsByTest, setResultsByTest] = useState({});
    const [selectedTest, setSelectedTest] = useState(null);
    const [permissions, setPermissions] = useState({});

    const fetchPermissions = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/profiles/me`, { withCredentials: true });
            setPermissions(response.data.data.permissions);
        } catch (error) {
            console.error('Error fetching user permissions:', error);
        }
    };

    useEffect(() => {
        fetchPermissions();
        fetchTests();
    }, []);

    const fetchTests = async () => {
        try {
            const response = await api.get('/tests');
            setTests(response.data.data.tests);
            fetchUserResultsByTests(response.data.data.tests);
        } catch (error) {
            console.error('Error fetching tests:', error);
        }
    };

    const fetchUserResultsByTests = async (tests) => {
        const results = {};
        for (let test of tests) {
            const response = await api.get(`/user-results/${test.test_id}`);
            results[test.test_id] = response.data.data;
        }
        setResultsByTest(results);
    };

    const handleResultAdded = () => {
        setShowForm(false);
        fetchUserResultsByTests(tests);
    };

    const userTests = tests.filter(test => resultsByTest[test.test_id] && resultsByTest[test.test_id].dates);

    const handleCardClick = (testId) => {
        setSelectedTest(testId);
    };

    const handleCloseDetails = () => {
        setSelectedTest(null);
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await api.post('/upload-file', formData);
                console.log('File upload response:', response.data);
                toast.success(response.data.data.message);  // Отображаем сообщение об успехе

                // Опционально обновляем список файлов или анализов
                // fetchImportedFiles();  // Реализуйте эту функцию при необходимости

            } catch (error) {
                console.error('File upload error:', error);

                const errorMessage = error.response?.data?.error || 'Ошибка при загрузке файла';
                toast.error(errorMessage);  // Отображаем сообщение об ошибке
            }
        }
    };

    return (
        <div className="container">
            <h1>Мои анализы</h1>
            <div className="button-container">
                <label className="add-button" onClick={() => setShowForm(true)}>
                    <FontAwesomeIcon icon={faPlus}/>
                </label>
                {permissions.import_files && (
                <label htmlFor="file-upload" className="add-button">
                    <FontAwesomeIcon icon={faUpload}/>
                </label>
                )}
            </div>

            <input
                type="file"
                id="file-upload"
                onChange={handleFileUpload}
                className="upload-button"
            />

            <ToastContainer />

            {showForm && (
                <Modal onClose={() => setShowForm(false)}>
                    <AddResultForm tests={tests} onResultAdded={handleResultAdded}/>
                </Modal>
            )}
            <div className="test-cards">
                {userTests.length > 0 ? (
                    userTests.map((test) => {
                        const lastResultIndex = resultsByTest[test.test_id].dates.length - 1;
                        const lastTestDate = resultsByTest[test.test_id].dates[lastResultIndex];
                        const lastParameters = resultsByTest[test.test_id].parameters.map(param => ({
                            name: param.name,
                            value: param.values[lastResultIndex].value,
                            is_reference: param.values[lastResultIndex].is_reference
                        }));
                        return (
                            <TestCard
                                key={test.test_id}
                                testId={test.test_id}
                                testName={test.test_name}
                                lastTestDate={lastTestDate}
                                parameters={lastParameters}
                                onClick={() => handleCardClick(test.test_id)}
                            />
                        );
                    })
                ) : (
                    <p>Нет данных</p>
                )}
            </div>
            {selectedTest && (
                <Modal onClose={handleCloseDetails}>
                    <TestDetailsModal
                        testId={selectedTest}
                        testName={tests.find(test => test.test_id === selectedTest).test_name}
                        onClose={handleCloseDetails}
                    />
                </Modal>
            )}
        </div>
    );
};

export default MyTests;
