import React, { useEffect, useState } from 'react';
import api from '../api';
import './styles/TestResultsView.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import AddResultForm from './AddResultForm';

const TestResultsView = ({ testId }) => {
    const [testResults, setTestResults] = useState(null);
    const [editingResultId, setEditingResultId] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchTestResults(page);
    }, [testId]);

    const fetchTestResults = async (page) => {
        try {
            const response = await api.post('/test-results', { test_id: testId, page });
            const data = response.data.data;

            // Если страница больше 1, добавляем новые результаты слева
            if (page > 1) {
                setTestResults(prev => ({
                    ...prev,
                    results: [...data.results, ...prev.results], // Добавляем результаты в начало
                    total_pages: data.total_pages,
                }));
            } else {
                setTestResults(data);
                setTotalPages(data.total_pages);
            }
        } catch (error) {
            console.error('Error fetching test results:', error);
        }
    };

    const handleEditClick = (resultId) => {
        setEditingResultId(resultId);
        setShowForm(true);
    };

    const handleResultAdded = () => {
        setShowForm(false);
        fetchTestResults(page);
    };

    const handleResultDeleted = (result_id) => {
        setShowForm(false);
        setTestResults(prev => {
            const updatedResults = prev.results.filter(result =>
                result.result_id !== result_id
            );

            return {
                ...prev,
                results: updatedResults,
            };
        });
    }

    const handleResultUpdated = (updatedResult) => {
        setTestResults(prev => {
            const updatedResults = prev.results.map(result =>
                result.result_id === updatedResult.result_id ? updatedResult : result
            );
            return {
                ...prev,
                results: updatedResults,
            };
        });
        setShowForm(false);
    };

    if (!testResults) {
        return <div>Loading...</div>;
    }

    return (
        <div className="test-results-container">
            <div className="pagination-controls">
                {page < totalPages && (
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        onClick={() => {
                            setPage(page + 1);
                            fetchTestResults(page + 1);
                        }}
                        className="pagination-arrow"
                    />
                )}
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Параметр</th>
                        {testResults.results.map((result) => (
                            <th key={result.result_id}>
                                {result.result_date}
                                <FontAwesomeIcon icon={faEdit} onClick={() => handleEditClick(result.result_id)} />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {testResults.parameters.map((param) => (
                        <tr key={param.parameter_id}>
                            <td>{param.parameter_name}</td>
                            {testResults.results.map((result) => (
                                <td key={result.result_id} className={result.result_parameter_values[param.parameter_id]?.is_reference ? 'reference-value' : ''}>
                                    {result.result_parameter_values[param.parameter_id]?.value}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {showForm && (
                <Modal onClose={() => setShowForm(false)}>
                    <AddResultForm
                        testId={testId}
                        resultId={editingResultId}
                        onResultAdded={handleResultAdded}
                        onResultUpdated={handleResultUpdated}
                        onResultDeleted={handleResultDeleted}
                    />
                </Modal>
            )}
        </div>
    );
};

export default TestResultsView;
